import React, { useEffect, useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"
import Newsletter from "../components/newsletter"
import OpenTime from "../components/opentime"
import { Link } from "gatsby"
import Footer from "../components/footer"
import Anchorlink from "../components/anchorlink"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import axios from "axios"

import Elia from "../images/elia-logo.png"
import Fraula from "../images/fraula.png"
import Check from "../images/checkinn-logo.png"

import IMG from "../images/news-img.jpg"

const Angebote = props => {
  const [users, setUsers] = useState(0)
  const contentData = props.data.prismicAngebote.data
  console.log(contentData)
  useEffect(() => {
    axios.get("https://helpyourlocalstore-newsletter.herokuapp.com/users", {}).then(function(response) {
      // handle success
      setUsers(response.data)
    })
  })
  return (
    <div>
      <Layout>
        <SEO
          title="Angebote | helpyourlocalstore"
          description="Profitieren Sie von unseren tollen Angeboten und bleiben Sie immer auf dem aktuellen Stand!"
        />
        <Nav />
        <div className="grid-container">
          <div id="angebote" className="angebote">
            <h2 className="angebote__headline">{contentData.headline.text}</h2>
            <div className="grid-x grid-margin-x">
              <div className="large-6 cell align-self-middle">
                <div className="angebote__newsletter">
                  <div className="angebote__content">
                    <h2>{contentData.cta_headline[0].text}</h2>
                    <p>{contentData.cta_text[0].text}</p>
                  </div>
                  <Newsletter mode="left" />
                  <div className="user-count">
                    {users > 0 ? (
                      <p>
                        {" "}
                        <span>{users}</span> Feinschmecker haben sich in den letzen Tagen eingetragen.
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="large-6 cell">
                <div className="angebote__img">
                  <Img fadeIn={true} fluid={props.data.angeboteImage.childImageSharp.fluid}></Img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid-container">
          <div className="angebote-advantages">
            <h3>Welche Vorteile bringt der Newsletter?</h3>
            <p className="angebote-advantages__pre-text">
              Trage dich jetzt in unseren exklusiven Feinschmecker-Newsletter ein und erhalte folgende Vorteile:
            </p>
            <div className="grid-x grid-margin-x align-center">
              {contentData.advantages.map(advantage => {
                return (
                  <div className="large-4 cell medium-6">
                    <div className="text-center">
                      <img src={advantage.advantage_icon.url}></img>
                      <h4>{advantage.advantage_title[0].text}</h4>
                      <p>{advantage.advantage_text[0].text}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="angebote-advantages">
            <h3 className="long-headline">
              Erhalte aktuelle Angebote von diesen und vielen weiteren Restaurants aus der Region!
            </h3>
            <div className="grid-x grid-margin-x align-center">
              <div className="large-4 cell text-center">
                <img className="store-logos" src={Elia}></img>
              </div>
              <div className="large-4 cell text-center">
                <img className="store-logos" src={Check}></img>
              </div>
              <div className="large-4 cell text-center">
                <img className="store-logos" src={Fraula}></img>
              </div>
            </div>
            <div className="text-center">
              <Anchorlink class="store-page__phone" href="/#angebote">
                Jetzt anmelden
              </Anchorlink>
            </div>
          </div>
        </div>
        <Footer />
      </Layout>
    </div>
  )
}

export const query = graphql`
  query Angebote {
    angeboteImage: file(relativePath: { eq: "news-img.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 660, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prismicAngebote {
      data {
        advantages {
          advantage_icon {
            url
          }
          advantage_text {
            text
          }
          advantage_title {
            text
          }
        }
        content {
          text
        }
        cta_headline {
          text
        }
        cta_text {
          text
        }
        headline {
          text
        }
      }
    }
  }
`
export default Angebote
