import React, { useEffect } from "react"
import { polyfill } from 'smoothscroll-polyfill';

const Anchorlink = (props) => {

    useEffect(() => {
        polyfill();
    })

    const smothScroll = (e) => {
        e.preventDefault();

        const href = e.currentTarget.getAttribute('href');
        const id = href.slice(href.indexOf('#') + 1);
        const element = document.getElementById(`${id}`);

        window.scroll({
            top: element.getBoundingClientRect().top + window.pageYOffset - props.offset,
            behavior: 'smooth',
        });
    }

    return (
        <a href={props.href} className={props.class} onClick={e => smothScroll(e)}>
            {props.children}
        </a>
    )
}
export default Anchorlink;